<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputSwitch
        :checked="details.executors_secondary_required"
        @toggle="save"
      ></InputSwitch>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/executors/primary'"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputSwitch from '@/common/ui/InputSwitch'

export default {
  name: 'ExecutorsSecondaryOption',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputSwitch
  },
  computed: {
    details() {
      return this.$store.getters.details
    },
    partner() {
      return this.$store.getters.partner
    },
    people() {
      return this.$store.getters.people
    },
    isValidated() {
      return true
    },
    number() {
      return '3'
    },
    heading() {
      return 'Would you like to name secondary executors?'
    },
    subHeading() {
      return 'You should plan for every eventuality and name people who can step in if the main executors are unable or un-willing to act.'
    },
    forwardTo() {
      if (this.details.executors_secondary_required)
        return '/executors/secondary'
      return '/executors/check_and_confirm'
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    save() {
      if (this.details) {
        this.$store.commit('details', {
          executors_secondary_required: !this.details
            .executors_secondary_required
        })
        if (this.details.executors_secondary_required === false) {
          this.resetExecutorsSecondary()
        }
      }
    },
    resetExecutorsSecondary() {
      this.people.forEach((person) => {
        person.executor_backup = false
        person.executor_secondary = false
        this.$store.commit('personAdd', person)
      })
      this.$store.commit('details', {
        executors_backup_required: false
      })
    }
  }
}
</script>

<style></style>
